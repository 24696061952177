import {
  FaFlagCheckered,
  FaCheckSquare,
  FaBook,
  FaBriefcase,
  FaGithub,
  FaInfinity,
  FaTools,
} from "react-icons/fa";
import {
  IoLogoHtml5,
  IoLogoCss3,
  IoLogoJavascript,
  IoLogoReact,
  IoLogoSass,
  IoLogoNodejs,
} from "react-icons/io5";
import {
  SiTypescript,
  SiRedux,
  SiStyledcomponents,
  SiChakraui,
  SiNpm,
  SiYarn,
  SiBabel,
  SiWebpack,
  SiEslint,
  SiAxios,
  SiKonva,
  SiExpress,
  SiNestjs,
  SiMongodb,
  SiMysql,
  SiJest,
  SiTestinglibrary,
  SiFastapi,
  SiFigma,
  SiPostman,
  SiTailwindcss,
  SiAmazonaws,
} from "react-icons/si";
import { TbBrandNextjs } from "react-icons/tb";
import React from "react";

const sublinks = [
  {
    page: "home",
    links: [
      // { label: "about Me", icon: <FaRegAddressCard />, url: "/home" }
    ],
  },
  {
    page: "skills",
    links: [
      {
        label: "technical skills",
        icon: <FaCheckSquare />,
        url: "#technical",
      },
      { label: "software skills", icon: <FaCheckSquare />, url: "/skills" },
    ],
  },
  {
    page: "experience",
    links: [
      { label: "Async Working", icon: <FaFlagCheckered />, url: "#experience" },
      { label: "Dell China", icon: <FaFlagCheckered />, url: "#experience" },
      { label: "Lenovo China", icon: <FaFlagCheckered />, url: "#experience" },
    ],
  },
  {
    page: "education",
    links: [
      { label: "Gerneral Assembly", icon: <FaBook />, url: "/education" },
      { label: "JR Academy", icon: <FaBook />, url: "/education" },
      { label: "Shantou University", icon: <FaBook />, url: "/education" },
    ],
  },
  {
    page: "projects",
    links: [
      {
        label: "Arina-Dev",
        icon: <FaBriefcase />,
        url: "https://www.arina-dev.com",
      },
      {
        label: "Artstar Studio",
        icon: <FaBriefcase />,
        url: "https://www.asdream.com.au",
      },
      {
        label: "CourtCanva",
        icon: <FaBriefcase />,
        url: "https://www.courtcanva.com/",
      },
      {
        label: "AsyncWorking",
        icon: <FaBriefcase />,
        url: "https://www.asyncworking.com/",
      },
      {
        label: "KoalaLooka",
        icon: <FaBriefcase />,
        url: "https://www.koalalooka.com.au/",
      },
    ],
  },
  {
    page: "tools",
    links: [{ label: "Dev Tools", icon: <FaTools />, url: "/tools" }],
  },
];

export const allskills = [
  {
    skillset: "Technical skills",
    skills: [
      {
        label: "FrontEnd",
        names: [
          {
            label: "HTML",
            icon: <IoLogoHtml5 />,
            url: "https://developer.mozilla.org/en-US/docs/Web/HTML",
          },
          {
            label: "CSS",
            icon: <IoLogoCss3 />,
            url: "https://developer.mozilla.org/en-US/docs/Web/CSS",
          },
          {
            label: "Javascript",
            icon: <IoLogoJavascript />,
            url: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
          },
          {
            label: "TypeScript",
            icon: <SiTypescript />,
            url: "https://www.typescriptlang.org/",
          },
          {
            label: "ReactJs",
            icon: <IoLogoReact />,
            url: "https://react.dev/",
          },
          { label: "Redux", icon: <SiRedux />, url: "https://redux.js.org/" },
          {
            label: "NextJs",
            icon: <TbBrandNextjs />,
            url: "https://nextjs.org/",
          },
          {
            label: "SASS",
            icon: <IoLogoSass />,
            url: "https://sass-lang.com/",
          },
          {
            label: "Styled-components",
            icon: <SiStyledcomponents />,
            url: "https://styled-components.com/",
          },
          {
            label: "ChakraUI",
            icon: <SiChakraui />,
            url: "https://chakra-ui.com/",
          },
          {
            label: "Tailwind CSS",
            icon: <SiTailwindcss />,
            url: "https://tailwindcss.com/",
          },
          { label: "NPM", icon: <SiNpm />, url: "https://www.npmjs.com/" },
          {
            label: "Yarn",
            icon: <SiYarn />,
            url: "https://classic.yarnpkg.com/en/",
          },
          { label: "Babel", icon: <SiBabel />, url: "https://babeljs.io/" },
          {
            label: "Webpack",
            icon: <SiWebpack />,
            url: "https://webpack.js.org/",
          },
          { label: "Eslint", icon: <SiEslint />, url: "https://eslint.org/" },
          { label: "Axios", icon: <SiAxios />, url: "https://www.axios.com/" },
          { label: "Konva", icon: <SiKonva />, url: "https://konvajs.org/" },
        ],
      },
      {
        label: "BackEnd and Database",
        names: [
          {
            label: "NodeJs",
            icon: <IoLogoNodejs />,
            url: "https://nodejs.org/",
          },
          {
            label: "Express.js",
            icon: <SiExpress />,
            url: "https://expressjs.org/",
          },
          { label: "NestJs", icon: <SiNestjs />, url: "https://nestjs.com/" },
          {
            label: "MongoDB",
            icon: <SiMongodb />,
            url: "https://mongodb.com/",
          },
          { label: "MySQL", icon: <SiMysql />, url: "https://www.mysql.com/" },
        ],
      },
      {
        label: "Testing",
        names: [
          { label: "Jest", icon: <SiJest />, url: "https://jestjs.io/" },
          {
            label: "React Testing Library",
            icon: <SiTestinglibrary />,
            url: "https://testing-library.com/",
          },
        ],
      },
      {
        label: "Architecture, Platforms and Methodologies",
        names: [
          {
            label: "AWS",
            icon: <SiAmazonaws />,
            url: "https://restfulapi.net/",
          },
          {
            label: "RESTful APIs",
            icon: <SiFastapi />,
            url: "https://restfulapi.net/",
          },
          { label: "GitHub", icon: <FaGithub />, url: "https://github.com/" },
          { label: "Figma", icon: <SiFigma />, url: "https://figma.com" },
          {
            label: "Postman",
            icon: <SiPostman />,
            url: "https://www.postman.com/",
          },
          {
            label: "Agile",
            icon: <FaInfinity />,
            url: "https://www.agilealliance.org/",
          },
        ],
      },
    ],
  },
  {
    skillset: "Soft skills",
    skills: [
      {
        label: "communication",
        names: "Good communication skills with colleagues and customers",
      },
      {
        label: "problem-solving",
        names: "Excellent problem solving skills and analytical skills",
      },
      {
        label: "growth mindset",
        names: "Have a growth mindset and eager to learn",
      },
      {
        label: "Languages",
        names: "English, Mandarin, Contanese",
      },
    ],
  },
];

export const experiences = [
  {
    id: "1",
    order: 3,
    title: "Full Stack Developer",
    dates: "Jan 2022 - Nov 2022",
    duties: [
      "Collaborate with BA to confirm UI/UX design requirements and build them using TypesScirpt, React, React Hooks, Context, Formik and Chakala UI, Material UI and develop reusable components for highly customized use cases",
      "Implement front-end unit tests using React Testing Library and Jest with over 80% coverage",
      "Develop backend RESTful API endpoints using NestJS and Postman",
      "Participate in the Data schema design and modify mongodb database schema",
      "Working as a member of a cross-functional agile development team",
      "Modifying & troubleshooting existing and new software components",
      "Manage code with Git and conduct daily code review",
      "Collaborate with the team by following Agile methodology and attending Agile ceremonies",
    ],
    company: "Async Working Pty Ltd",
  },
  {
    id: "2",
    order: 2,
    title: "Senior System Consultant",
    dates: "Dec 2010 - Nov 2013",
    duties: [
      "Facilitated growth in our customer accounts we were managing across various industries including IDC, Manufacturing, Internet and Entertainment, Telecom, Finance. Our customer focused approach included regular contact and communication and needs analysis to define business requirements and provide system architecture solutions and consultancy services",
      "Defined and evaluated deficiency of customers’ IT systems.  Our mission was to help customers solve IT problems. With comprehensive IT infrastructure solutions and industry background best practice, we shared, presented and helped them develop IT system layout in both short term and long term",
      "Proposed technical solution documents, bidding files and training materials",
      "Took weekly trainings and vendor’s trainings to catch up latest technical solutions and products knowledge including Server, Storage (SAN, NAS, DAS), Networking and new solutions including HPCC, Cloud Computing, Server Virtualization, VDI (Virtual Desktop Infrastructure), IDM (Intelligent Data Management), System Management and Deployment, Network Secure, Disaster Recovery, Unix-X86 migration etc.,",
      "Team-worked with sales team and service team to accomplish each assigned project",
    ],
    company: "Dell (China) Company Limited",
  },
  {
    id: "3",
    order: 1,
    title: "Solution and Product Engineer",
    dates: "Aug 2005 - Mar 2010",
    duties: [
      "Provided technical support on Think brand PC and notebook and ThinkVantage solution in south and southwest China",
      "Visited customers and provide tailored system services, assets management solution and security management solutions etc.,",
      "Self-learning on new technology and products,  deliver regular products and solution trainings to sales team and distributors to help them understand latest products and advantage",
      "Proposed and published appropriate Sales Guide/Sales Bible for Account Sales, Channel Sales and Outlet Sales teams in an attempt to improve team selling skills.",
      "Conducted new product presentation in multiple marketing events including new product launch seminar and Think Salon",
    ],
    company: "Lenovo Inc.(China)",
  },
];

export const education = [
  {
    id: "1",
    order: 3,
    degree: "Full Stack Web Development",
    school: "JR Academy",
  },
  {
    id: "2",
    order: 2,
    degree: "React Development",
    school: "General Assembly",
  },
  {
    id: "3",
    order: 3,
    degree: "Bachelor of Computer Science",
    school: "Shantou University",
  },
];

export default sublinks;

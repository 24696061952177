import React, { useState } from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import { experiences } from "../utils/data";
import styled from "styled-components";

function Experience() {
  const [jobs, setJobs] = useState(experiences);
  const [value, setValue] = useState(0);

  const { company, dates, duties, title } = jobs[value];

  return (
    <Wrapper>
      <section className="section" id="experience">
        <div className="title">
          <h2>Experience</h2>
          <div className="underline"></div>
        </div>
        <div className="tabs-jobs-center">
          {/* button container */}
          <div className="tabs-btn-container">
            {jobs.map((item, index) => {
              return (
                <button
                  key={item.id}
                  onClick={() => setValue(index)}
                  className={`tabs-job-btn ${
                    value === index && "tabs-active-btn"
                  }`}
                >
                  {item.company}
                </button>
              );
            })}
          </div>

          {/* job info */}
          <article className="tabs-job-info">
            <h3>{title}</h3>
            {/* <h4>{company}</h4> */}
            <h4>{dates}</h4>
            {duties.map((duty, index) => {
              return (
                <div key={index} className="tabs-job-desc">
                  <FaAngleDoubleRight className="tabs-job-icon" />
                  <p>{duty}</p>
                </div>
              );
            })}
          </article>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.main`
  background-color: var(--clr-yellow-light);
  color: var(--clr-yellow-dark);
  padding: 8rem 0;
  /* margin-bottom: 4rem; */

  .tabs-jobs-center {
    width: 80vw;
    margin: 0 auto;
    max-width: var(--max-width);
  }
  .tabs-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 4rem;
    flex-wrap: wrap;
  }
  .tabs-job-btn {
    background: transparent;
    border-color: transparent;
    text-transform: capitalize;
    font-size: 1.2rem;
    letter-spacing: var(--spacing);
    margin: 0 0.5rem;
    transition: var(--transition);
    cursor: pointer;
    padding: 0.25rem 0;
    line-height: 1;
    outline-color: var(--clr-yellow-dark);
  }

  .tabs-job-btn:hover {
    color: var(--clr-yellow-dark);
    box-shadow: 0 2px var(--clr-yellow-dark);
  }

  .tabs-active-btn {
    color: var(--clr-yellow-dark);
    box-shadow: 0 2px var(--clr-yellow-dark);
  }

  .tabs-job-info h3 {
    font-weight: 400;
  }

  .tabs-job-info h4 {
    text-transform: uppercase;
    color: var(--clr-grey-1);
    background: var(--clr-grey-9);
    display: inline-block;
    padding: 0.375rem 0.75rem;
    border-radius: var(--radius);
  }

  .tabs-job-info p {
    color: var(--clr-grey-1);
  }
  .tabs-job-date {
    letter-spacing: var(--spacing);
  }

  .tabs-job-desc {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 2rem;
    align-items: center;
    margin-bottom: 1.25rem;
  }

  .tabs-job-desc p {
    margin-bottom: 0;
    color: var(--clr-grey-1);
    font-size: 1rem;
  }

  .job-icon {
    color: var(--clr-yellow-dark);
  }

  @media screen and (min-width: 992px) {
    .tabs-jobs-center {
      width: 90vw;
      display: grid;
      grid-template-columns: 200px 1fr;
      column-gap: 4rem;
    }

    .tabs-btn-container {
      flex-direction: column;
      justify-content: flex-start;
    }

    .tabs-job-btn {
      margin-bottom: 1rem;
    }

    .tabs-active-btn {
      box-shadow: -2px 0 var(--clr-yellow-dark);
    }

    .tabs-job-btn:hover {
      box-shadow: -2px 0 var(--clr-yellow-dark);
    }
  }

  .tabs-btn {
    text-transform: uppercase;
    background: var(--clr-primary-5);
    color: var(--clr-primary-9);
    padding: 0.375rem 0.75rem;
    letter-spacing: var(--spacing);
    font-weight: 700;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    font-size: 0.875rem;
    border: 2px solid transparent;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: var(--radius);
    display: block;
    width: 12rem;
    text-align: center;
    margin: 0 auto;
    margin-top: 3rem;
  }

  .tabs-btn:hover {
    color: var(--clr-yellow-dark);
    background: var(--clr-yellow-dark);
  }
`;

export default Experience;

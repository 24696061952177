import logo from "./logo.svg";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Hero from "./components/Hero";
import Submenu from "./components/Submenu";
import Footer from "./components/Footer";
import Experience from "./components/Experience";
import Skills from "./components/Skills";

function App() {
  return (
    <>
      <Navbar />
      <Sidebar />
      <Hero />
      <Submenu />
      <Skills />
      <Experience />
      <Footer />
    </>
  );
}

export default App;

import React, { useState } from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import { allskills } from "../utils/data";
import styled from "styled-components";

export default function Skills() {
  const frontendLink = allskills[0].skills[0].names;
  const backendLink = allskills[0].skills[1].names;
  const testingLink = allskills[0].skills[2].names;
  const otherLink = allskills[0].skills[3].names;
  return (
    <Wrapper>
      <section className="section" id="technical">
        <div className="title">
          <h2>Technical Skills</h2>
          <div className="underline"></div>
        </div>
        {allskills[0].skills.map((skill, index) => {
          return (
            <div className="technical_skill_box">
              <div className="title_box">
                <h3>{skill.label}</h3>
              </div>
              <ul>
                {skill.names.map((item) => {
                  return (
                    <li key={item.label}>
                      <a
                        href={item.url}
                        alt={item.label}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.icon}
                        {item.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: var(--clr-green-light);
  color: var(--clr-green-dark);
  padding: 8rem 0;
  .title .underline {
    background: var(--clr-green-dark);
  }
  .skills_box {
  }
  .technical_skill_box {
    /* background-color: var(--clr-grey-9); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1rem 3rem;
    flex: 1;
    h3 {
      text-align: center;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 8rem;
        height: 3rem;
        display: flex;
        background: linear-gradient(
          130deg,
          var(--clr-green-light),
          var(--clr-purple-dark) 41.07%,
          var(--clr-primary-5) 76.05%
        );
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0.2rem;
        border-radius: var(--radius-big);
        background-color: var(--clr-green-dark);
        margin: 0.5rem;
        font-size: 1rem;

        svg {
          margin-right: 0.5rem;
          font-size: 1.2rem;
          color: white;
        }
        transition: var(--transition);
      }
      li:hover {
        transform: scale(1.1);
      }
      a {
        color: white;
      }
    }
  }
  .title_box {
    display: flex;
    align-items: center;
    h4 {
      text-transform: uppercase;
      color: var(--clr-grey-1);
      background: var(--clr-grey-9);
      display: inline-block;
      border-radius: var(--radius);
    }
  }
`;

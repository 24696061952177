import React from "react";
import logo from "../images/logo-arina.png";
import { FaBars } from "react-icons/fa";
import { useGlobalContext } from "../context";
import sublinks from "../utils/data";

const Navbar = () => {
  const { openSidebar, openSubmenu, closeSubmenu } = useGlobalContext();
  const navlinks = sublinks.map((link) => link.page);

  const displaySubmenu = (e) => {
    // console.log(e.target);
    const page = e.target.textContent;
    // console.log(page);
    const tempBtn = e.target.getBoundingClientRect();
    console.log(tempBtn);
    const center = (tempBtn.left + tempBtn.right) / 2;
    const bottom = tempBtn.bottom - 3;
    openSubmenu(page, { center, bottom });
  };

  const handleSubmenu = (e) => {
    if (!e.target.classList.contains("link-btn")) {
      closeSubmenu();
    }
  };

  return (
    <nav className="nav" onMouseOver={handleSubmenu}>
      <div className="nav-center">
        <div className="nav-header">
          <img src={logo} className="nav-logo" alt="Arina Dev" />
          <button className="btn toggle-btn" onClick={openSidebar}>
            <FaBars />
          </button>
        </div>
        <ul className="nav-links">
          {navlinks.map((link, index) => {
            return (
              <li key={index}>
                <button className="link-btn" onMouseOver={displaySubmenu}>
                  {link}
                </button>
              </li>
            );
          })}
        </ul>
        <a
          className="btn signin-btn"
          target="_blank"
          href="https://www.linkedin.com/in/arina-ke/"
          rel="noreferrer"
        >
          Linkedin
        </a>
      </div>
    </nav>
  );
};

export default Navbar;

import React from "react";
import aboutme from "../images/aboutme.png";
import { useGlobalContext } from "../context";

const Hero = () => {
  const { closeSubmenu } = useGlobalContext();
  return (
    <section className="hero" onMouseMove={closeSubmenu}>
      <div className="hero-center">
        <article className="hero-info">
          <h1>ARINA KE</h1>
          <h3>I am a Full Stack Developer</h3>
          <p>
            I am a motivated and proactive full stack developer with a growth
            mindset and experience in web development. I equip myself with
            knowledge and skills in programming language and popular frameworks.
            I also gained solid IT knowledge in programing language, algorithms,
            databases, OS with a bachelor's degree in Computer Science.
          </p>
          <button className="btn-round">Download Resume</button>
        </article>
        <article className="hero-images">
          <img src={aboutme} alt="girl" className="girl-img" />
        </article>
      </div>
    </section>
  );
};

export default Hero;
